import { publicAPI } from './axios';

export async function getGameResult(user_id) {
  try {
    const response = await publicAPI.get(`/game-result/?user_id=${user_id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function subscribe(data) {
  try {
    const response = await publicAPI.post('/subscriber/', data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
}
