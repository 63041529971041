import { Helmet } from 'react-helmet';
import PublicLayout from '../layouts/PublicLayout';
import classNames from 'classnames';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import TElement from '../components/T-element';
import Loading from '../components/Loading';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkGameStatus } from '../api/home';
import { getGameResult, subscribe } from '../api/result';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { EnvelopeIcon, UsersIcon } from '@heroicons/react/16/solid';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import SuccessAlert from '../components/SuccessAlert';

const schema = z.object({
  name: z.string().min(1, 'Name is required'),
  email: z.string().min(1, 'Email is required').email('Invalid Email'),
});

const Result = () => {
  const [open, setOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false)
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const {
    data: checkData,
    isError: isCheckError,
    isPending: isCheckingPending,
  } = useQuery({
    queryKey: ['check-status'],
    queryFn: () => checkGameStatus(localStorage.getItem('worlde_user_id')),
  });

  useEffect(() => {
    if (checkData) {
      if (checkData.status) {
        navigate('/');
      }
    }
  }, [navigate, checkData]);

  const reminderMutation = useMutation({
    mutationFn: (data) => subscribe(data),
    onSuccess: () => {
      console.log('success');
      setOpen(false);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { data, isError, isPending } = useQuery({
    queryKey: ['game-result'],
    queryFn: () => getGameResult(localStorage.getItem('worlde_user_id')),
  });
  if (isPending || isCheckingPending) {
    return (
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex flex-col gap-16">
        <Loading />
      </div>
    );
  }
  if (isError || isCheckError) {
    return (
      <div className="bg-lightGreen w-full h-full flex justify-center items-center flex flex-col gap-16">
        <div className="text-lg text-red-600 font-sans px-10">
          An error occurred while fetching the result data. Please try again
          later.
        </div>
      </div>
    );
  }
  return (
    <PublicLayout>
      <Helmet>
        <title>Result | DILEMMA</title>
      </Helmet>
      <div className="bg-lightGreen w-full h-full flex flex-col justify-center items-center overflow-auto">
        <button
          className="font-sans text-border text-xl pt-20 hover:font-semibold"
          onClick={() => setOpen((open) => !open)}
        >
          Click here to receive reminders!
        </button>
        <p className="fixed top-4 md:right-8 right-6 lg:right-12 xl:right-20 font-sans text-border text-sm md:text-base lg:text-lg xl:text-xl">
          Streaks:&nbsp;&nbsp;{data.streak}
        </p>
        <p className="fixed top-10 lg:top-14 right-6 md:right-8 lg:right-12 xl:right-20 font-sans text-border text-sm md:text-base lg:text-lg xl:text-xl">
          Total:&nbsp;&nbsp;{data.count}
        </p>
        <div className="container flex justify-center items-center flex flex-col gap-16">
          <div className="flex gap-6 md:gap-32 mt-10">
            <div className="flex justify-center flex-col items-center gap-4 md:min-w-[320px] max-w-[320px]">
              <TElement
                percent={
                  (100 * data.answers[0].count) /
                  (data.answers[0].count + data.answers[1].count)
                }
              />
              <p className="text-2xl md:text-4xl lg:text-5xl text-border font-number italic font-medium">
                {(
                  (100 * data.answers[0].count) /
                  (data.answers[0].count + data.answers[1].count)
                ).toFixed(0)}
                %
              </p>
              <p
                className={classNames(
                  'capitalize text-border text-lg md:text-2xl lg:text-3xl font-sans tracking-widest',
                  { 'text-white underline': data?.answers[0].status }
                )}
              >
                {data?.answers[0].personality}
              </p>
              <p className="capitalize text-borer text-sm md:text-xl lg:text-2xl text-border text-center">
                {data?.answers[0].name}
              </p>
            </div>
            <div className="flex justify-center flex-col items-center gap-4 md:min-w-[320px] max-w-[320px]">
              <TElement
                percent={
                  (100 * data.answers[1].count) /
                  (data.answers[0].count + data.answers[1].count)
                }
              />
              <p className="text-2xl md:text-4xl lg:text-5xl text-border font-number italic font-medium">
                {(
                  (100 * data.answers[1].count) /
                  (data.answers[0].count + data.answers[1].count)
                ).toFixed(0)}
                %
              </p>
              <p
                className={classNames(
                  'capitalize text-border text-lg md:text-2xl lg:text-3xl font-sans tracking-widest',
                  { 'text-white underline': data?.answers[1].status }
                )}
              >
                {data?.answers[1].personality}
              </p>
              <p className="capitalize text-borer text-sm md:text-xl lg:text-2xl text-border text-center">
                {data?.answers[1].name}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between w-full px-4 sm:px-8 md:px-16 lg:px-24 xl:px-40">
            {/* <a
              href="/streak"
              className="px-4 sm:px-8 py-2 bg-white rounded-2xl hover:opacity-80 font-sans text-border text-sm sm:text-base md:text-xl lg:text-2xl"
            >
              Streak
            </a> */}
            <p className="lg:w-28"></p>
            <p className="font-sans text-border text-xl md:text-3xl lg:text-4xl text-center">
              Does the poll seem
              <br /> off? Come back <br /> and check in later!
            </p>

            <button
              className="px-4 sm:px-8 py-2 bg-white rounded-2xl hover:opacity-80 font-sans text-border text-sm sm:text-base md:text-xl lg:text-2xl"
              onClick={() => navigate('/personality-trait')}
            >
              Library
            </button>
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-sm sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <form
                onSubmit={handleSubmit((data) => reminderMutation.mutate(data))}
              >
                <div className="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    className="text-base font-semibold text-gray-900"
                  >
                    Get Reminders!
                  </DialogTitle>
                  <div className="mt-2">
                    <div>
                      <label
                        htmlFor="query"
                        className="block text-left text-sm/6 font-medium text-gray-900"
                      >
                        Full Name
                      </label>
                      <div className="mt-0 flex">
                        <div className="-mr-px grid grow grid-cols-1 focus-within:relative">
                          <input
                            id="query"
                            type="text"
                            placeholder="John Smith"
                            className="col-start-1 row-start-1 block w-full rounded-l-md bg-white py-1.5 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:pl-9 sm:text-sm/6"
                            {...register('name')}
                          />
                          <UsersIcon
                            aria-hidden="true"
                            className="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400 sm:size-4"
                          />
                          {errors.name && (
                            <p className="text-red-500 text-[0.7rem] font-sans pt-2 text-left pl-3">
                              {errors.name.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="email"
                        className="block text-left text-sm/6 font-medium text-gray-900"
                      >
                        Email
                      </label>
                      <div className="mt-0 grid grid-cols-1">
                        <input
                          id="email"
                          type="text"
                          placeholder="you@example.com"
                          className="col-start-1 row-start-1 block w-full rounded-md bg-white py-1.5 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:pl-9 sm:text-sm/6"
                          {...register('email')}
                        />
                        <EnvelopeIcon
                          aria-hidden="true"
                          className="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400 sm:size-4"
                        />
                        {errors.email && (
                          <p className="text-red-500 text-[0.7rem] font-sans pt-2 text-left pl-3">
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <button
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button>
                </div>
              </form>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
      <SuccessAlert open={isAlertOpen} onClose={() => setIsAlertOpen(false)}/>
    </PublicLayout>
  );
};

export default Result;
